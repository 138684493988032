import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import privacyPolicy from '../assets/images/privacy-policy.jpg'

const PrivacyPolicy = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Privacy Policy" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Privacy Policy" 
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                                <img src={privacyPolicy} alt="pp" />
                                <p><i>This Privacy Policy was last updated on April, 2022.</i></p>
                                <h3>1. The information we collect</h3>
                                <p>Success Occupational Therapy operates the website successtherapy.com.au. As part of our operations we may gather certain types of information about the users of out site:</p>
                                <ul>
                                    <li>Personally Identifiable Information. This is provided by you when you register for services that we may offer. Examples of these services could include email newsletters or events. Providing this information will always be optional for you. However, some services may not be available to you if you choose not to provide it.;</li>
                                    <li>Aggregated Data. This information is generated by our systems as they track traffic through our sites. This information does not identify you personally and is not linked to the personally identifiable information that you may have provided;</li>
                                </ul>
                                <h3>2. Who we share this information with</h3>
                                <ul>
                                    <li>The information may be hosted with a service provider. Our agreements with them protect the information that we collect from any unauthorised use;</li>
                                    <li>The aggregated data (not the personally identifiable information) may be shared with third parties such as advertisers or business partners;</li>
                                    <li>Your personally identifiable information will only be shared with third parties when we believe in good faith that we are required to do so by law;</li>
                                    <li>Only if your prior permission has been obtained (which we would usually do at the time of collection), we may share the information with specified third parties.</li>
                                </ul>
                                <h3>3. What else we do with personally identifiable information</h3>
                                <p>We have collected this information in order to provide the best possible service to you while you are visiting our site. To this end we may use the information that you provide for the following purposes:</p>
                                <ul>
                                    <li>To verify your identity if you need help with a forgotten password or you are having login problems with one of our site services;</li>
                                    <li>To process any transactions that you might make on our site;</li>
                                    <li>To help provide any other services that you have requested;</li>
                                    <li>To offer the most relevant information suitable to you and your interests;</li>
                                    <li>For any marketing, promotional, publicity, marketing or market research that we might undertake;</li>
                                    <li>For any other purposes for which you have given permission.</li>
                                </ul>
                                <h3>4. What about cookies?</h3>
                                <p>A cookie is a small file that resides on your computer and is recognised by our server when you visit our sites. A cookie does not provide us with any personally identifiable information. It does provide details of your IP address, the computer platform that you use, the browser that you use and what domain you are accessing our sites from.</p>
                                <h3>5. With this information we can do the following:</h3>
                                <ul>
                                    <li>Track traffic patterns to our site;</li>
                                    <li>Ensure that the most relevant content is being shown;</li>
                                    <li>Allow you to enter certain site member services without having to log in each time you visit;</li>
                                    <li>If you would rather we did not use cookies with you, you can refuse them by turning them off in your browser and/or deleting them from your hard drive. You will still be able to visit our site.</li>
                                </ul>
                                <h3>6. What about our advertisers and business partners?</h3>
                                <p>This Privacy Policy only relates to our sites. You should be aware that we are not responsible for the practices of our advertisers or business partners. Our site may contain links to other sites. You should check their privacy policies before providing personally identifiable information to them or any other third party.</p>
                                <h3>7. Opting in and opting out</h3>
                                <p>You will always have the option to opt in to certain services and to opt out of those services at any stage. This means you may change your mind at any stage about participating in any of our member services.</p>
                                <h3>8. Your right to access your information</h3>
                                <p>The Privacy Act gives you the right to access and correct your personal information. To find out more about how to do this, please send an email with your corrections to hello@successtherapy.com.au.</p>
                                <h3>9. Email marketing</h3>
                                <p>Success Occupational Therapy sends marketing emails to recipients who have created a Success Occupaitonal Therapy account or have provided their email address to a marketing partner of Success Occupational Therapy.</p>
                                <p>If you no longer wish to receive marketing emails from Success Occupational Therapy, you may click the “unsubscribe” link in the email</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms-and-conditions">
                                                Terms and Conditions
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                

                                
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;